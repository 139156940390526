import React, { useState } from 'react';
import { Upload, X, FileText } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import pdfToText from 'react-pdftotext';
import mammoth from 'mammoth';

export default function ContentReviewer() {
  const [file, setFile] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [pastedContent, setPastedContent] = useState('');
  const [reviewResult, setReviewResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleFileUpload = async (e) => {
    const uploadedFile = e.target.files[0];
    console.log('File selected:', uploadedFile);
    setFile(uploadedFile);
    setIsLoading(true);
    setError('');
    setExtractedText('');

    try {
      let text = '';
      if (uploadedFile.type === 'application/pdf') {
        console.log('Extracting text from PDF');
        text = await extractTextFromPDF(uploadedFile);
      } else if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        console.log('Extracting text from DOCX');
        text = await extractTextFromDOCX(uploadedFile);
      } else {
        throw new Error('Unsupported file type');
      }
      console.log('Extracted text:', text.substring(0, 100) + '...'); // Log the first 100 characters
      setExtractedText(text);
    } catch (error) {
      console.error('Error extracting text:', error);
      setError(`Error extracting text: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const extractTextFromPDF = (file) => {
    return pdfToText(file)
      .then((text) => {
        console.log('PDF extraction successful');
        return text;
      })
      .catch((error) => {
        console.error('PDF extraction error:', error);
        throw error;
      });
  };

  const extractTextFromDOCX = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  const handleContentPaste = (e) => {
    setPastedContent(e.target.value);
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setReviewResult('');

    const content = extractedText || pastedContent;
    console.log('Content to be reviewed:', content.substring(0, 100) + '...'); // Log the first 100 characters

    if (!content) {
      setError('Please upload a file or paste content to review.');
      setIsLoading(false);
      return;
    }

    try {
      console.log('Sending review request to worker');
      const response = await fetch('https://brandguidelines.paul-maxwell-campbell.workers.dev/review', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }

      const data = await response.json();
      console.log('Review result received:', data.review.substring(0, 100) + '...'); // Log the first 100 characters
      setReviewResult(data.review);
    } catch (error) {
      console.error('Error reviewing content:', error);
      setError(`Error reviewing content: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const removeFile = () => {
    setFile(null);
    setExtractedText('');
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleReviewSubmit} className="space-y-4">
        <div>
          <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
            Upload PDF or Word document
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            {file ? (
              <div className="flex items-center space-x-2">
                <FileText className="h-6 w-6 text-blue-500" />
                <span className="text-sm text-gray-500">{file.name}</span>
                <button
                  type="button"
                  onClick={removeFile}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" accept=".pdf,.docx" className="sr-only" onChange={handleFileUpload} />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">PDF or DOCX up to 10MB</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <label htmlFor="content-paste" className="block text-sm font-medium text-gray-700">
            Or paste content here
          </label>
          <Textarea
            id="content-paste"
            value={pastedContent}
            onChange={handleContentPaste}
            rows={6}
            placeholder="Paste your content here..."
            className="mt-1"
          />
        </div>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Reviewing...' : 'Review Content'}
        </Button>
      </form>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {reviewResult && (
        <Alert>
          <AlertTitle>Review Result</AlertTitle>
          <AlertDescription className="mt-2 whitespace-pre-wrap">{reviewResult}</AlertDescription>
        </Alert>
      )}
    </div>
  );
}
