import React, { useState } from 'react';
import { AlertCircle, Check, ChevronDown, ChevronUp } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Slider } from '../ui/slider';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../ui/collapsible';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import ContentReviewer from './ContentReviewer';

export default function BrandAlignedContentGenerator() {
  const [audience, setAudience] = useState('');
  const [contentRequest, setContentRequest] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);

  // New state for advanced options
  const [goal, setGoal] = useState('');
  const [formFactor, setFormFactor] = useState('');
  const [formalCasual, setFormalCasual] = useState(50);
  const [seriousFunny, setSeriousFunny] = useState(50);
  const [respectfulIrreverent, setRespectfulIrreverent] = useState(50);
  const [matterOfFactEnthusiastic, setMatterOfFactEnthusiastic] = useState(50);
  const [additionalOption1, setAdditionalOption1] = useState('');
  const [additionalOption2, setAdditionalOption2] = useState('');
  const [freeFormText, setFreeFormText] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setOutput('');
  
    const requestData = {
      audience,
      query: contentRequest,
      goal,
      formFactor,
      voiceAndTone: {
        formalCasual,
        seriousFunny,
        respectfulIrreverent,
        matterOfFactEnthusiastic,
      },
      additionalOption1,
      additionalOption2,
      freeFormText,
    };
  
    // Log the data being sent to the Cloudflare worker
    console.log('Data being sent to Cloudflare worker:', requestData);

    try {
        const response = await fetch('https://brandguidelines.paul-maxwell-campbell.workers.dev', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
    
        const data = await response.json();
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        console.log('Raw voice and tone values:', data.rawVoiceAndTone);
        console.log('Interpreted instructions:', data.interpretedInstructions);
        setOutput(data.content);
      } catch (error) {
        setError('Error generating content. Please try again.');
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold text-center mb-6">Brand-Aligned Content Tool</h1>
            </div>
            <Tabs defaultValue="generate" className="w-full">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="generate">Generate Content</TabsTrigger>
                <TabsTrigger value="review">Review Content</TabsTrigger>
              </TabsList>
              <TabsContent value="generate">
                <form onSubmit={handleSubmit} className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="relative">
                    <select
                      id="audience"
                      value={audience}
                      onChange={(e) => setAudience(e.target.value)}
                      className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
                      required
                    >
                      <option value="">Select Audience</option>
                      <option value="substitute-teachers">Substitute Teachers</option>
                      <option value="school-partners">School Partners</option>
                    </select>
                    <label htmlFor="audience" className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                      Audience
                    </label>
                  </div>
                  <div className="relative">
                    <textarea
                      id="contentRequest"
                      value={contentRequest}
                      onChange={(e) => setContentRequest(e.target.value)}
                      rows="4"
                      className="peer h-auto w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
                      placeholder="Enter your content request here..."
                      required
                    ></textarea>
                    <label htmlFor="contentRequest" className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                      Content Request
                    </label>
                  </div>

                  <Collapsible
                    open={isAdvancedOptionsOpen}
                    onOpenChange={setIsAdvancedOptionsOpen}
                    className="w-full space-y-2"
                  >
                    <CollapsibleTrigger className="flex items-center justify-between w-full p-2 font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <span>Advanced Options</span>
                      {isAdvancedOptionsOpen ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                    </CollapsibleTrigger>
                    <CollapsibleContent className="space-y-4">
                      <div className="space-y-2">
                        <h3 className="font-medium">Project Details</h3>
                        <Select onValueChange={setGoal}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Goal" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="inform">Inform</SelectItem>
                            <SelectItem value="entertain">Entertain</SelectItem>
                            <SelectItem value="drive-action">Drive Action</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div className="space-y-2">
                        <h3 className="font-medium">Form Factor</h3>
                        <Select onValueChange={setFormFactor}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Form Factor" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="ad-unit">Ad unit</SelectItem>
                            <SelectItem value="blog-post">Blog post</SelectItem>
                            <SelectItem value="email-newsletter">Email newsletter</SelectItem>
                            <SelectItem value="infographic">Infographic content</SelectItem>
                            <SelectItem value="landing-page-copy">Landing page</SelectItem>
                            <SelectItem value="one-pager">One-pager</SelectItem>
                            <SelectItem value="podcast-outline">Podcast outline</SelectItem>
                            <SelectItem value="press-release">Press release</SelectItem>
                            <SelectItem value="product-description">Product description</SelectItem>
                            <SelectItem value="social-post">Social post</SelectItem>
                            <SelectItem value="video-script">Video script</SelectItem>
                            <SelectItem value="white-paper">White paper</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div className="space-y-2">
                        <h3 className="font-medium">Voice & Tone</h3>
                        <div className="space-y-4">
                          <div>
                            <span className="text-sm">Formal - Casual</span>
                            <Slider
                              value={[formalCasual]}
                              onValueChange={(value) => setFormalCasual(value[0])}
                              max={100}
                              step={1}
                            />
                          </div>
                          <div>
                            <span className="text-sm">Serious - Funny</span>
                            <Slider
                              value={[seriousFunny]}
                              onValueChange={(value) => setSeriousFunny(value[0])}
                              max={100}
                              step={1}
                            />
                          </div>
                          <div>
                            <span className="text-sm">Respectful - Irreverent</span>
                            <Slider
                              value={[respectfulIrreverent]}
                              onValueChange={(value) => setRespectfulIrreverent(value[0])}
                              max={100}
                              step={1}
                            />
                          </div>
                          <div>
                            <span className="text-sm">Matter-of-fact - Enthusiastic</span>
                            <Slider
                              value={[matterOfFactEnthusiastic]}
                              onValueChange={(value) => setMatterOfFactEnthusiastic(value[0])}
                              max={100}
                              step={1}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <h3 className="font-medium">Additional Options</h3>
                        <Select onValueChange={setAdditionalOption1}>
                          <SelectTrigger>
                            <SelectValue placeholder="Additional Option 1" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="option1">Option 1</SelectItem>
                            <SelectItem value="option2">Option 2</SelectItem>
                            <SelectItem value="option3">Option 3</SelectItem>
                          </SelectContent>
                        </Select>
                        <Select onValueChange={setAdditionalOption2}>
                          <SelectTrigger>
                            <SelectValue placeholder="Additional Option 2" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="optionA">Option A</SelectItem>
                            <SelectItem value="optionB">Option B</SelectItem>
                            <SelectItem value="optionC">Option C</SelectItem>
                          </SelectContent>
                        </Select>
                        <textarea
                          value={freeFormText}
                          onChange={(e) => setFreeFormText(e.target.value)}
                          placeholder="Enter additional details here..."
                          className="w-full p-2 border rounded-md"
                          rows="3"
                        />
                      </div>
                    </CollapsibleContent>
                  </Collapsible>

                  <div className="relative">
                    <button type="submit" className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 w-full">
                      {isLoading ? 'Generating...' : 'Generate Content'}
                    </button>
                  </div>
                </form>
              </TabsContent>
              <TabsContent value="review">
                <ContentReviewer />
              </TabsContent>
            </Tabs>
            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {output && (
              <Alert>
                <Check className="h-4 w-4" />
                <AlertTitle>Generated Content</AlertTitle>
                <AlertDescription className="mt-2 whitespace-pre-wrap">{output}</AlertDescription>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}