import React from 'react';
import BrandAlignedContentGenerator from './components/BrandAlignedContentGenerator';

function App() {
  return (
    <div className="App">
      <BrandAlignedContentGenerator />
    </div>
  );
}

export default App;